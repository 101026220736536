


























































































import { Vue, Component } from "vue-property-decorator";
import { ComponentOptions } from "vue/types/options";

import { take } from "rxjs/operators";

import CityDarkBGComponent from "@/components/core/backgrounds/CityDarkBG.vue";
import MediaCarouselComponent from "@/components/media/carousel/MediaCarousel.vue";
import SlideGroupComponent from "@/components/media/slide-group/SlideGroup.vue";
import CarouselPageSkeletonLoader from "@/components/media/carousel/CarouselPageSkeletonLoader.vue";

import { DCSLanguageService, EventService, MetaService } from "@/services";
import { Event } from "@/types/event.interface";

import VueMarkdown from "vue-markdown";

const metaInfo: ComponentOptions<Vue>['metaInfo'] = {
  title: 'Where will Dow Building Science be next?',
  meta: [
    {
      vmid: 'description',
      name: 'description',
      content: 'Learn about conferences, tradeshows and webinars in which Dow Building Science team members will be participating.',
    },
  ],
};

export const metaInfoMixin = {
  metaInfo,
};

@Component({
  mixins: [metaInfoMixin],
  components: {
    CarouselPageSkeletonLoader,
    CityDarkBGComponent,
    MediaCarouselComponent,
    SlideGroupComponent,
    VueMarkdown
  },
  computed: {
    formattedDateLong(): string {
      return new Intl.DateTimeFormat(undefined, {
        weekday: "long",
        month: "long",
        day: "numeric"
      }).formatRange(
        new Date(this.$data.featuredEvent.showDate.startDate),
        new Date(this.$data.featuredEvent.showDate.endDate)
      );
    },
    formattedDateShort(): string {
      return new Intl.DateTimeFormat(undefined, {
        month: "long",
        day: "numeric"
      }).formatRange(
        new Date(this.$data.featuredEvent.showDate.startDate),
        new Date(this.$data.featuredEvent.showDate.endDate)
      );
    },
    formattedTime(): string {
      const startDate = new Date(this.$data.featuredEvent.showDate.startDate);

      // the date needs to be changed so that only time is displayed
      const endDate = new Date(this.$data.featuredEvent.showDate.endDate);
      endDate.setDate(startDate.getDate());
      endDate.setMonth(startDate.getMonth());
      endDate.setFullYear(startDate.getFullYear());

      return new Intl.DateTimeFormat(undefined, {
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
      }).formatRange(startDate, endDate);
    }
  },
})
export default class Events extends Vue {
  public DCSLanguageService = new DCSLanguageService();

  private eventService = new EventService();
  private metaService = new MetaService();

  public featuredEvent: Event | null | undefined = null;
  public events: Event[] = [];

  public formattedDateLong!: string;
  public formattedDateShort!: string;
  public formattedTime!: string;

  hasNoEvents = false;
  isLoading = true;

  eventSelected(index: number): void {
    this.featuredEvent = this.events[index];
  }

  private created(): void {
    this.metaService.updateMetaDescription('Learn about conferences, tradeshows and webinars in which Dow Building Science team members will be participating.');
    this.metaService.updateMetaTitle('Where will Dow Building Science be next?');

    this.eventService
      .getUpcomingEventList()
      .pipe(take(1))
      .subscribe((events: Event[]) => {
        this.events = events.sort((a, b) => 
          (a.isFeatured === b.isFeatured)? 0 : a.isFeatured? -1 : 1
        );
        this.featuredEvent = events[0] ?? null;
        if(this.events.length==0)
          this.hasNoEvents=true;
        this.isLoading = false;
      });
  }

  public formatDescription(description: string): string[] {
    return description.split('\n');
  }
}
