var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carousel" },
    [
      _c("div", { staticClass: "title" }, [
        _c("h1", [
          _vm._v(
            " " + _vm._s(_vm.DCSLanguageService.translate("EventTitle")) + " "
          )
        ])
      ]),
      _vm.featuredEvent
        ? _c("div", { staticClass: "text-media-container" }, [
            _c("div", { staticClass: "text-container" }, [
              _c("h2", [_vm._v(" " + _vm._s(_vm.featuredEvent.title) + " ")]),
              _c("div", { staticClass: "detail-container" }, [
                _c(
                  "div",
                  { staticClass: "details" },
                  [
                    _c("v-icon", { staticClass: "detail-icon" }, [
                      _vm._v(" mdi-map-marker-radius-outline ")
                    ]),
                    _c("div", { staticClass: "detail-item" }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm.featuredEvent.location.eventLocation))
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.featuredEvent.location.city +
                              ", " +
                              _vm.featuredEvent.location.country
                          )
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "details" },
                  [
                    _c("v-icon", { staticClass: "detail-icon" }, [
                      _vm._v(" mdi-calendar-clock ")
                    ]),
                    _c("div", { staticClass: "detail-item" }, [
                      _c("p", [_vm._v(_vm._s(_vm.formattedDateLong))]),
                      _c("p", [_vm._v(_vm._s(_vm.formattedTime))])
                    ])
                  ],
                  1
                )
              ]),
              _c(
                "p",
                { staticClass: "description" },
                [
                  _c("vue-markdown", {
                    attrs: { source: _vm.featuredEvent.description }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        href: _vm.featuredEvent.registrationLink,
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.DCSLanguageService.translate("RegisterNow")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "aspect-ratio",
                    rawName: "v-aspect-ratio",
                    value: "16:9",
                    expression: "'16:9'"
                  }
                ],
                staticClass: "media-container"
              },
              [
                _c("MediaCarouselComponent", {
                  attrs: { media: _vm.featuredEvent.media }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "slide-group-container" },
        [
          _vm.events.length
            ? _c("SlideGroupComponent", {
                attrs: { "carousel-items": _vm.events },
                on: { "item-select": _vm.eventSelected }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.hasNoEvents
        ? _c("div", { staticClass: "display-image" }, [
            _c("img", {
              staticClass: "display-image",
              attrs: { src: require("@/assets/no_events.svg") }
            }),
            _c("h2", [
              _vm._v(
                " " + _vm._s(_vm.DCSLanguageService.translate("NoEvents")) + " "
              )
            ])
          ])
        : _vm._e(),
      _vm.isLoading ? _c("CarouselPageSkeletonLoader") : _vm._e(),
      _c("CityDarkBGComponent", {
        attrs: { "modifier-class": "dark-bg--fixed" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }